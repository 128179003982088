import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {EnvService} from '../../../shared/services/env.service';


@Injectable({
    providedIn: 'root'
})
export class DemandeDevisService {
    AUTH_API: any;
    constructor(private http: HttpClient, private envService: EnvService) {
        this.AUTH_API = this.envService.apiUrl;
    }

    getAllDevis(): Observable<any> {
        return this.http.get(this.AUTH_API + `api/devis/getAll`);
    }

    getAllArtisan(): Observable<any> {
        return this.http.get(this.AUTH_API + `artisans/getAll`);
    }

    cancelDevis(data): Observable<any> {
        return this.http.post(this.AUTH_API + `demande_devis/change_status`, data);
    }

    assignerDemandeDevis(data): Observable<any> {
        return this.http.post(this.AUTH_API + `demande_devis/assignation`, data);
    }

    changeStatutDemandeDevis(data): Observable<any> {
        return this.http.post(this.AUTH_API + `api/devis/change/status`, data);
    }

    getRelation(url: String) : Observable<any>{
        return this.http.get(this.AUTH_API + `${url.replace('/v2/', "")}`)
    }
    editFicheDevis(data): Observable<any> {
        return this.http.post(this.AUTH_API + `api/fiche_devis/update`, data);
    }

    deleteDocumentDevis(data): Observable<any> {
        return this.http.delete(this.AUTH_API + `api/devis/delete_document/${data}`);
    }

    deleteDevis(data) :Observable<any> {
        return this.http.delete(this.AUTH_API + `api/delete/devis/${data}`);;
    }

}
