import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {EnvService} from '../../../shared/services/env.service';


@Injectable({
    providedIn: 'root'
})
export class DemandeReservationsService {
    AUTH_API: any;
    httpOptions = {
        headers: new HttpHeaders({ 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json'})
    };
    constructor(private http: HttpClient, private envService: EnvService) {
        this.AUTH_API = this.envService.apiUrl;
    }

    getAllReservations(): Observable<any> {
        return this.http.get(this.AUTH_API + `api/reservations/getAll`);
    }

    deletePrestation(data) :Observable<any> {
        return this.http.delete(this.AUTH_API + `api/delete/prestation/${data}`);;
    }

    getAllArtisan(): Observable<any> {
        return this.http.get(this.AUTH_API + `api/artisans`);
    }

    getUtilisateur(url: String) : Observable<any>{
        return this.http.get(this.AUTH_API + `${url.replace('/v2/', "")}`)
    }
    getRelation(url: string) : Observable<any>{
        // console.log(url);
        return this.http.get(this.AUTH_API + `${url.replace('/v2/', "")}`)
    }
    

    cancelReservations(data): Observable<any> {
        return this.http.post(this.AUTH_API + `reservation_prestations/change_status`, data);
    }

    assignerDemandeReservations(data): Observable<any> {
        return this.http.post(this.AUTH_API + `api/assigner/reservation`, data);
    }

    changeStatutDemandeReservations(data): Observable<any> {
        return this.http.post(this.AUTH_API + `api/reservation/change/status`, data);
    }

    editFichePrestation(data): Observable<any> {
        return this.http.post(this.AUTH_API + `api/fiche_prestation/update`, data);
    }

    deleteDocument(data): Observable<any> {
        return this.http.delete(this.AUTH_API + `api/prestation/delete_document/${data}`);
    }
    

}
