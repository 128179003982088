import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class SharedDataService {
  private _listDemandeDevis = new Subject<any>();
  private _listPieces = new Subject<any>();
  private _listDemandeDevisEntreprise = new Subject<any>();
  private _listDemandeForfaitPose = new Subject<any>();
  private _listDemandeAbonnementEntreprise = new Subject<any>();
  private _listDepannageEntreprise = new Subject<any>();
  private _listBesoinsEntreprise = new Subject<any>();
  private _listDemandeReservation = new Subject<any>();
  private _listArtisan = new Subject<any>();
  private _listClient = new Subject<any>();
  private _listFournisseur = new Subject<any>();
  private _listMateriel = new Subject<any>();
  private _listTypeDevis = new Subject<any>();
  private _listTypeReservations = new Subject<any>();
  private _listCodePromo = new Subject<any>();
  private _listAdministrateurs = new Subject<any>();
  private _listBonEntree = new Subject<any>();
  private _listBonSortie = new Subject<any>();
  private _listSousPrestation = new Subject<any>()
  private _listPubs = new Subject<any>()

  constructor() { }

  sendRefreshListDemandeDevis() {
    this._listDemandeDevis.next();
  }
  sendRefreshListPieces() {
    this._listPieces.next();
  }
  sendRefreshListBesoinsEntreprise() {
    this._listBesoinsEntreprise.next();
  }

  sendRefreshListDemandeForfaitPose() {
    this._listDemandeForfaitPose.next();
  }

  sendRefreshListDemandeDevisEntreprise() {
    this._listDemandeDevisEntreprise.next();
  }
  sendRefreshListDemandeAbonnementEntreprise() {
    this._listDemandeAbonnementEntreprise.next();
  }
  sendRefreshListDepannageEntreprise() {
    this._listDepannageEntreprise.next();
  }

  sendRefreshListDemandeReservation() {
    this._listDemandeReservation.next();
  }

  sendRefreshListArtisan() {
    this._listArtisan.next();
  }

  sendRefreshListClient() {
    this._listClient.next();
  }

  sendRefreshListFournisseur() {
    this._listFournisseur.next();
  }

  sendRefreshListMateriel() {
    this._listMateriel.next();
  }

  sendRefreshListTypeDevis() {
    this._listTypeDevis.next();
  }

  sendRefreshListTypeReservations() {
    this._listTypeReservations.next();
  }
  sendRefreshListPubs() {
    this._listPubs.next();
  }

  sendRefreshListCodePromo() {
    this._listCodePromo.next();
  }

  sendRefreshListAdministrateur() {
    this._listAdministrateurs.next();
  }

  sendRefreshListBonEntree() {
    this._listBonEntree.next();
  }

  sendRefreshListBonSortie() {
    this._listBonSortie.next();
  }
  sendRefreshListSousPrestation(){
    this._listSousPrestation.next()
  }

  getClickEventRefreshTableListDemandeDevis(): Observable<any> {
    return this._listDemandeDevis.asObservable();
  }

  getClickEventRefreshTableListPieces(): Observable<any> {
    return this._listPieces.asObservable();
  }

  getClickEventRefreshTableListPubs(): Observable<any> {
    return this._listPubs.asObservable();
  }

  getClickEventRefreshTableListBesoinsEntreprise(): Observable<any> {
    return this._listBesoinsEntreprise.asObservable();
  }

  getClickEventRefreshTableListDemandeForfaitPose(): Observable<any> {
    return this._listDemandeForfaitPose.asObservable();
  }

  getClickEventRefreshTableListDepannageEntreprise(): Observable<any> {
    return this._listDepannageEntreprise.asObservable();
  }

  getClickEventRefreshTableListAbonnementEntreprise(): Observable<any> {
    return this._listDemandeAbonnementEntreprise.asObservable();
  }

  getClickEventRefreshTableListDemandeDevisEntreprise(): Observable<any> {
    return this._listDemandeDevisEntreprise.asObservable();
  }

  getClickEventRefreshTableListDemandeReservation(): Observable<any> {
    return this._listDemandeReservation.asObservable();
  }

  getClickEventRefreshTableListArtisan(): Observable<any> {
    return this._listArtisan.asObservable();
  }

  getClickEventRefreshTableListClient(): Observable<any> {
    return this._listClient.asObservable();
  }

  getClickEventRefreshTableListFournisseur(): Observable<any> {
    return this._listFournisseur.asObservable();
  }

  getClickEventRefreshTableListMateriel(): Observable<any> {
    return this._listMateriel.asObservable();
  }

  getClickEventRefreshTableListTypeDevis(): Observable<any> {
    return this._listTypeDevis.asObservable();
  }

  getClickEventRefreshTableListTypeReservations(): Observable<any> {
    return this._listTypeReservations.asObservable();
  }

  getClickEventRefreshTableListSousPrestation(): Observable<any> {
    return this._listSousPrestation.asObservable();
  }

  getClickEventRefreshTableListCodePromo(): Observable<any> {
    return this._listCodePromo.asObservable();
  }

  getClickEventRefreshTableListAdministrateur(): Observable<any> {
    return this._listAdministrateurs.asObservable();
  }

  getClickEventRefreshTableListBonEntree(): Observable<any> {
    return this._listBonEntree.asObservable();
  }

  getClickEventRefreshTableListBonSortie(): Observable<any> {
    return this._listBonSortie.asObservable();
  }


}
